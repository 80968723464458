<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="我要提现"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
            <template #right>
                <p class="left-top" @click="subsidiaryClick">明细</p>
            </template>
        </van-nav-bar>
        <div class="div">
            <div class="Withdrawal-top">
                <img src="../../assets/images/book_bean_img.png" alt="" class="book_bean_img">
                <div class="Withdrawal-center">
                    <p class="title">{{indexList.coin}}</p>
                    <p class="subtitle">约{{indexList.coin_rmb}}元</p>
                </div>
                <p class="text">书豆余额</p>
            </div>
            <p class="header-title">书豆提现</p>
            <div class="topup-list">
                <template v-for="(item,index) in indexList.coin_list">
                    <div class="topup-div" :class="index == coinNumber?'active':''" @click="indexClick(item,index)">
                        <p class="topup-title">提现{{item.rmb}}元</p>
                        <p class="topup-text">{{item.coin}}书豆</p>
                    </div>
                </template>
            </div>
            <!--            <p class="number">请输入书豆数量提现</p>-->
            <!--            <div>-->
            <!--                <div class="money_list">-->
            <!--                    <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon">-->
            <!--                    <input type="number" placeholder="例如：358900" class="input" v-model="input_coin">-->
            <!--                    <p class="money_right" @click="allClick">全部</p>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <p class="xian"></p>
        <div class="div">
            <p class="header-title">提现方式</p>

            <div class="account" @click="accountClick" v-if="at_onceForm.type">
                <div class="account-left">
                    <p>
                        <van-icon v-if="at_onceForm.type =='1'" name="wechat" color="#0bba06" size="20"/>
                        <van-icon v-if="at_onceForm.type =='2'" name="alipay" color="#02a9e8" size="20"/>
                    </p>
                    <p class="account-title">
                        <span v-if="at_onceForm.type =='1'">微信账户</span>
                        <span v-if="at_onceForm.type =='2'">支付宝账户</span>
                        {{at_onceForm.name}}</p>
                </div>
                <p>
                    <van-icon name="arrow" color="#909399" size="15"/>
                </p>
            </div>
            <div class="account" @click="accountClick" v-else>
                <div class="account-left">
                    <p>
                        <van-icon name="add" color="#4D77FD" size="20"/>
                    </p>
                    <p class="account-title">添加提现账户</p>
                </div>
                <p>
                    <van-icon name="arrow" color="#909399" size="15"/>
                </p>
            </div>
            <p class="account-button" @click="progressClick">确定</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Withdrawal",
        data() {
            return {
                indexList: [],
                coinNumber: Number,
                Time: {},
                input_coin: '',
                at_onceForm: {
                    money: '',
                    coin: '',
                    name: this.$route.query.name,
                    type: this.$route.query.type,
                    account: this.$route.query.phone,
                },
            }
        },
        created() {
            this.indexGet()
        },
        methods: {
            indexClick(item, index) {
                this.coinNumber = index
                this.Time = item
                this.at_onceForm.money = item.rmb
                this.at_onceForm.coin = item.coin
            },
            indexGet() {
                this.$axios.get('/api/withdraw/index')
                    .then(res => {
                        var res = res.data.data
                        this.indexList = res
                    })
            },
            allClick() {
                this.input_coin = this.indexList.coin
            },
            subsidiaryClick() {
                this.$router.push({
                    path: '/subsidiary'
                })
            },
            progressClick() {
                if (this.coinNumber == Number) {
                    this.$toast('请选择提现金额')
                    return
                }
                if(!this.at_onceForm.type){
                    this.$toast('请选择提现账户')
                    return;
                }
                this.$dialog.alert({
                    title: '提现',
                    message: '确定要进行提现吗？',
                    showCancelButton: 'true',
                    confirmButtonText: '确定',
                    confirmButtonColor: '#4D77FD',
                    cancelButtonColor: '#606266',
                    cancelButtonText: '再想想',
                }).then(() => {
                    this.$axios.post('/api/withdraw/at_once', this.at_onceForm)
                        .then(res => {
                            this.$toast.success('提现成功')
                            this.$router.push({
                                path: '/subsidiary'
                            })
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                })
                    .catch(() => {
                        this.coinNumber = Number
                    })
            },
            accountClick() {
                this.$router.push({
                    path: '/account'
                })
            }
        }
    }
</script>

<style scoped>
    .account-button {
        width: 670px;
        height: 88px;
        background: rgba(77, 119, 253, 1);
        /*opacity:0.5;*/
        border-radius: 44px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        margin-top: 68px;
        margin-bottom: 60px;
    }

    .account-title {
        color: #606266;
        font-size: 28px;
        margin-left: 20px;
    }

    .account-left {
        display: flex;
        align-items: center;
    }

    .account {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 53px;
    }

    .xian {
        height: 20px;
        background: #F2F5F8;
        margin-bottom: 40px;
    }

    .input::-webkit-input-placeholder {
        color: #BFC2CC;
    }

    .input {
        width: 100%;
        font-size: 28px;
        color: #303133;
        background: none;
    }

    .money_right {
        color: #4D77FD;
        font-size: 28px;
        width: 150px;
        text-align: right;
    }

    .money_list {
        display: flex;
        align-items: center;
        background: #F2F5F8;
        padding: 20px 40px;
        margin: 40px 0;
    }

    .bookbean_icon {
        width: 26px;
        height: 27px;
        margin-right: 10px;
    }

    .number {
        color: #606266;
        font-size: 28px;
    }

    .header-title {
        font-size: 36px;
        color: #0A1C33;
        margin-bottom: 30px;
    }

    .text {
        font-size: 30px;
        color: #0A1C33;
        margin-top: 32px;
    }

    .subtitle {
        padding: 10px 15px;
        color: #fff;
        background: #F5325E;
        font-size: 20px;
        border-radius: 20px 20px 20px 0;
    }

    .title {
        font-size: 42px;
        font-weight: bold;
        color: #0A1C33;
        margin-right: 10px;
    }

    .Withdrawal-center {
        display: flex;
    }

    .Withdrawal-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }

    .topup-top {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        background: #4D77FD;
        color: #fff;
        padding: 10px 15px;
        border-radius: 0 8px 0 10px;
    }

    .active {
        border: 2px solid #4D77FD !important;
        background: #fff !important;
    }

    .active:after {
        content: '';
        background: url("../../assets/images/active.png") no-repeat;
        background-size: 42px;
        width: 42px;
        height: 42px;
        display: inline-block;
        position: absolute;
        right: -1px;
        bottom: -1px;
    }

    .active .topup-title, .active .topup-text {
        color: #4D77FD !important;
    }

    .topup-text {
        color: #909399;
        font-size: 24px;
    }

    .topup-title {
        color: #606266;
        font-size: 28px;
        margin-bottom: 21px;
    }

    .topup-div {
        width: 320px;
        height: 160px;
        padding: 20px;
        border: 2px solid #fff;
        background: rgba(242, 245, 248, 1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        position: relative;
    }

    .topup-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .book_bean_img {
        width: 121px;
        height: 115px;
        margin-bottom: 20px;
    }

    .left-top {
        font-size: 26px;
        color: #606266;
    }
</style>